import React from "react";
import { Route, Switch, useHistory, withRouter } from "react-router-dom";
import PrivateRoute from "./components/privateroute/PrivateRoute";
import ls from 'local-storage'

import Layout from "./components/layout/Layout";

import Login from "./containers/login/Login";
import Dashboard from "./containers/dashboard/Dashboard";
import Users from "./containers/users/Users";
import TambahUser from "./containers/users/Tambah";
import DetailUser from "./containers/users/Detail";
import Transaksi from "./containers/transaksi/Transaksi";
import TambahTransaksi from "./containers/transaksi/Tambah";
import DetailTransaksi from "./containers/transaksi/Detail";
import ProdukECommerce from "./containers/produkECommerce/Produk";
import TambahProdukECommerce from "./containers/produkECommerce/Tambah";
import DetailProdukECommerce from "./containers/produkECommerce/Detail";
import ProdukEGrocery from "./containers/produkEGrocery/Produk";
import TambahEGrocery from "./containers/produkEGrocery/Tambah";
import DetailProdukEGrocery from "./containers/produkEGrocery/Detail";
import KategoriECommerce from "./containers/kategoriECommerce/Kategori";
import TambahKategoriECommerce from "./containers/kategoriECommerce/Tambah";
import DetailKategoriECommerce from "./containers/kategoriECommerce/Detail";
import KategoriEGrocery from "./containers/kategoriEGrocery/Kategori";
import TambahKategoriEGrocery from "./containers/kategoriEGrocery/Tambah";
import DetailKategoriEGrocery from "./containers/kategoriEGrocery/Detail";
import Role from "./containers/role/role";
import TambahRole from "./containers/role/Tambah";
import DetailRole from "./containers/role/Detail";
import { jwtDecode } from "./helpers/helpers";
import Profil from "./containers/profil/Profil";
import ProdukTravel from "./containers/produkTravel/Produk";
import TambahProdukTravel from "./containers/produkTravel/Tambah";
import DetailProdukTravel from "./containers/produkTravel/Detail";
import Cicilan from "./containers/cicilan/Cicilan";

const App = props => {
  const path = props.location.pathname;
  const history = useHistory();

  if (path === "/login") {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
      </Switch>
    );
  } else {
    let token = ls.get('token');

    if (token === null || token === undefined) {
      history.push('/login');
      return false;
    }

    var role_id = jwtDecode(token).role_id;
    //console.log(role_id);

    return (
      <>
        <Switch>
          <Layout>
            <PrivateRoute exact path="/" component={Dashboard} />

            <PrivateRoute exact path="/transaksi" component={Transaksi} />
            <PrivateRoute exact path="/transaksi/detail/:id" component={DetailTransaksi} />

            <PrivateRoute exact path="/profil" component={Profil} />

            {role_id == 1 && (
              <>
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/users/tambah" component={TambahUser} />
                <PrivateRoute exact path="/users/detail/:id" component={DetailUser} />

                <PrivateRoute exact path="/role" component={Role} />
                <PrivateRoute exact path="/role/tambah" component={TambahRole} />
                <PrivateRoute exact path="/role/detail/:id" component={DetailRole} />

                <PrivateRoute exact path="/ecommerce/produk" component={ProdukECommerce} />
                <PrivateRoute exact path="/ecommerce/produk/tambah" component={TambahProdukECommerce} />
                <PrivateRoute exact path="/ecommerce/produk/detail/:id" component={DetailProdukECommerce} />

                <PrivateRoute exact path="/ecommerce/kategori" component={KategoriECommerce} />
                <PrivateRoute exact path="/ecommerce/kategori/tambah" component={TambahKategoriECommerce} />
                <PrivateRoute exact path="/ecommerce/kategori/detail/:id" component={DetailKategoriECommerce} />

                <PrivateRoute exact path="/egrocery/produk" component={ProdukEGrocery} />
                <PrivateRoute exact path="/egrocery/produk/tambah" component={TambahEGrocery} />
                <PrivateRoute exact path="/egrocery/produk/detail/:id" component={DetailProdukEGrocery} />

                <PrivateRoute exact path="/egrocery/kategori" component={KategoriEGrocery} />
                <PrivateRoute exact path="/egrocery/kategori/tambah" component={TambahKategoriEGrocery} />
                <PrivateRoute exact path="/egrocery/kategori/detail/:id" component={DetailKategoriEGrocery} />
  
                <PrivateRoute exact path="/travel/produk" component={ProdukTravel} />
                <PrivateRoute exact path="/travel/produk/detail/:id" component={DetailProdukTravel} />

                <PrivateRoute exact path="/cicilan" component={Cicilan} />
              </>
            )}
            {role_id == 3 && (
              <>
                <PrivateRoute exact path="/ecommerce/produk" component={ProdukECommerce} />
                <PrivateRoute exact path="/ecommerce/produk/tambah" component={TambahProdukECommerce} />
                <PrivateRoute exact path="/ecommerce/produk/detail/:id" component={DetailProdukECommerce} />

                <PrivateRoute exact path="/egrocery/produk" component={ProdukEGrocery} />
                <PrivateRoute exact path="/egrocery/produk/tambah" component={TambahEGrocery} />
                <PrivateRoute exact path="/egrocery/produk/detail/:id" component={DetailProdukEGrocery} />
              </>
            )}

            {role_id == 4 && (
              <>
                <PrivateRoute exact path="/travel/produk" component={ProdukTravel} />
                <PrivateRoute exact path="/travel/produk/tambah" component={TambahProdukTravel} />
                <PrivateRoute exact path="/travel/produk/detail/:id" component={DetailProdukTravel} />

                <PrivateRoute exact path="/cicilan" component={Cicilan} />
              </>
            )}

          </Layout>
        </Switch>
      </>
    );
  }
};

export default withRouter(App);
